import { openDB } from 'idb';
import * as Sentry from '@sentry/vue';

const dbName = 'cetli';
const dbVersion = 1;

const db = openDB(dbName, dbVersion, {
  upgrade(db) {
    [
      'addresses',
      'events',
      'eventGroups',
      'eventTypes',
      'instruments',
      'roles',
      'schoolDistricts',
      'userRoles',
      'users',
    ].forEach(store => {
      if (!db.objectStoreNames.contains(store)) {
        db.createObjectStore(store, { keyPath: 'id' });
      }
    });
  },
});

export const readAllFromIndexedDB = async (storeName: string) => {
  try {
    const dbPromise = await db;
    const data = await dbPromise.getAll(storeName);
    const count = data.length ?? 0;

    let lastUpdateTime: string | undefined;
    if (data?.length > 0) {
      lastUpdateTime = getLatestTimestamp(data.map(item => item.updateTime));
    }
    return { data, count, lastUpdateTime };
  } catch (error) {
    Sentry.captureException(JSON.stringify({
      type: 'indexdb-read-all-error',
      error,
    }));
    console.error('Error reading all from IndexedDB:', error);
    throw error;
  }
};

export const readFromIndexedDB = async (storeName: string, key: string) => {
  try {
    const dbPromise = await db;
    const data = await dbPromise.get(storeName, key);
    return data;
  } catch (error) {
    Sentry.captureException(JSON.stringify({
      type: 'indexdb-read-error',
      error,
    }));
    console.error('Error reading from IndexedDB', error);
    throw error;
  }
};

export const writeToIndexedDB = async (storeName: string, data: any) => {
  try {
    const dbPromise = await db;
    await dbPromise.put(storeName, data);
  } catch (error) {
    Sentry.captureException(JSON.stringify({
      type: 'indexdb-write-error',
      error,
    }));
    console.log('Failed to write to inexedDB', error);
  }
};

const getLatestTimestamp = (timestamps: string[]): string | undefined => {
  // Filter out undefined values and convert the remaining strings to Date objects
  const validTimestamps = timestamps.filter(timestamp => timestamp);
  if (validTimestamps.length === 0) return;

  // Convert valid timestamps to milliseconds
  const timestampsInMillis = validTimestamps.map(timestamp => new Date(timestamp).getTime());

  // Find the latest date from the valid timestamps
  const latestTimestamp = new Date(Math.max(...timestampsInMillis));

  // Return the latest timestamp as a string
  return latestTimestamp.toISOString();
};
